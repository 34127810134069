import React from 'react'

import { graphql } from 'gatsby'
import PortableText from '@sanity/block-content-to-react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import StageLinks from './stage-links'

import Collapsible from 'react-collapsible'

import { IoIosArrowDown } from "react-icons/io";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Button } from 'react-bootstrap';

import styles from './stage-page.module.css'
import general from '../styles/general.module.css'

import serializers from '../lib/serializers'
import ImageViewer from 'react-simple-image-viewer'
import LinkLists from '../lib/linkLists'
import SEO from '../components/seo'

export const query = graphql`
    query StagePageQuery($id: String!) {
        stagePage: sanityContentPage(id: { eq: $id }) {
            id
            title
            subTitle
            directory
            _rawContentSections(resolveReferences: {maxDepth: 20})
            additionalContent {
                id
                title
                linkListShowAs
                showAsAccordion
                showIconsWithToolTip
                linkList {
                  title
                  openLinkInNewWindow
                  internalLink {
                    reference {
                      ... on SanityIcon {
                        id
                        title
                        iconImage {
                          asset {
                            url
                          }
                        }
                        _rawTooltipText(resolveReferences: {maxDepth: 20})
                      }
                      ... on SanityFileDocument {
                        id
                        title
                        _rawFile(resolveReferences: {maxDepth: 20})
                        _rawDescription(resolveReferences: {maxDepth: 20})
                      }
                    }
                  }
                }
              }
        }
        stageSteps: sanityRiskAssessementSteps {
            steps {
                id
            }
        }
    }
`

class StagePage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            subSideContent: [],
            activeAccordion: 0,
            currentImage: [],
            isViewerOpen: false,
        }
    }

    openImageViewer = (url) => {
        this.setState({
            currentImage: [url],
            isViewerOpen: true
        })
    };
     
    closeImageViewer = () => {
        this.setState({
            currentImage: [],
            isViewerOpen: false
        })
    };

    openAccordion = (content, idx) => {
        const subSideContent = [];

        if (content.sideContent) {
            if (content.sideContent.linkLists) {
                const linkLists = <LinkLists key={content._key} content={content.sideContent.linkLists} styles={styles} />
                subSideContent.push(linkLists);
            }
            if (content.sideContent.sideImage) {
                subSideContent.push(this.getSideImage(content.sideContent.sideImage));
            }
            if (content.sideContent.quote) {
                subSideContent.push(this.getQuote(content.sideContent.quote));
            }
        }

        this.setState({
            activeAccordion: idx,
            subSideContent: subSideContent,
        })
    }

    closeAccordion = (idx) => {
        if (this.state.activeAccordion === idx) {
            this.setState({
                activeAccordion: 0,
                subSideContent: []
            })
        }
    }

    getSideImage = (sideImage) => {
        const fullImage = sideImage.FullImage && sideImage.FullImage.asset ? sideImage.FullImage.asset : null;
        const smallImage = sideImage.smallImage && sideImage.smallImage.asset ? sideImage.smallImage.asset : null;
        const image = smallImage !== null ? smallImage : fullImage 

        if (image !== null) {
            return (
                <div key={image.assetId} className={styles.sideImageContainer}>
                    <span>{sideImage.description}</span>
                    <img src={image.url} className={general.imageViewer} onClick={ () => this.openImageViewer(fullImage ? fullImage.url : smallImage.url) } />
                </div>
            )
        }
    }

    getQuote = (quote) => {
        return (
            <div className={styles.quoteContainer} key={quote.mainImage.asset.assetId}>
                <img src={quote.mainImage.asset.url} className={general.imageViewer} onClick={ () => this.openImageViewer(quote.mainImage.asset.url) }></img>
                <div>
                    <p>{quote.quote}</p>
                    <p className={styles.author}>- {quote.authors[0].author.name}</p>
                </div>
            </div>
        )
    }

    render() {
        const data = this.props.data;

        if (!data.stagePage) {
            throw new Error(
                'Missing "Stage Page". Open the Studio and some content in "Stage Page"'
            )
        }

        const stageData = data.stagePage;
        const stageNumber = this.props.pageContext.stageNumber;
        const directory = stageData.directory;
        const additionalContent = stageData.additionalContent;

        const mainSideContent = <LinkLists content={additionalContent} styles={styles} />;

        if (stageData._rawContentSections.length === 0) {
            return (
                <Layout>
                    <div className={styles.root}>
                        <p className={styles.upperLeftLink}><Link to={directory} style={{ textDecoration: 'none' }}>Home</Link> / Risk assessment / Stage {stageNumber}</p>
                        <div className={general.stageLinks}>
                            <StageLinks stageNumber={stageNumber} />
                        </div>
                        <h1>No stage data yet</h1>
                    </div>
                </Layout>
            )
        }

        const contentComponents = stageData._rawContentSections.map((content, idx) => {
            if (content.showAsAccordion !== true) {
                if (!content.paragraph) {
                    return (
                        <div key={content._key}>
                            <h1>{content.contentSectionTitle}</h1>
                            <h2>{content.contentSectionSubTitle}</h2>
                        </div>
                    )
                }

                return (
                    <div key={content._key}>
                        <h1>{content.contentSectionTitle}</h1>
                        <h2>{content.contentSectionSubTitle}</h2>
                        <PortableText
                            blocks={content.paragraph}
                            serializers={serializers}
                            className={general.portableText}
                        />
                    </div>
                )
            } else {
                const triggerTitle =
                    <div className={styles.triggerTitle}>
                        {content.contentSectionTitle}
                        <span><IoIosArrowDown /></span>
                    </div>

                return (
                    <Collapsible trigger={triggerTitle} key={content._key}
                        className={styles.sectionBox}
                        triggerClassName={styles.sectionTrigger}
                        triggerOpenedClassName={styles.sectionTriggerOpened}
                        openedClassName={styles.sectionOpened}
                        transitionTime={100}
                        transitionCloseTime={100}
                        onOpening={() => this.openAccordion(content, idx)}
                        onClosing={() => this.closeAccordion(idx)}
                        open={this.state.activeAccordion === idx ? true : false}
                    >
                        <div className={styles.sectionContent} style={{ display: this.state.activeAccordion === idx ? 'block' : 'none' }}>
                            <h2>{content.contentSectionSubTitle}</h2>
                            <PortableText
                                blocks={content.paragraph}
                                serializers={serializers}
                                className={styles.portableText}
                            />
                        </div>
                    </Collapsible>
                )
            }
        })

        const stepLength = data.stageSteps.steps.length;

        let buttonComponents = '';

        if (stageNumber === 1) {
            buttonComponents =
                <div className={styles.buttons}>
                    <Link to={`${directory}` + `/${stageNumber + 1}`}><Button className={general.button}><span>Continue to Stage {stageNumber + 1}</span><FaAngleRight /></Button></Link>
                </div>
        } else if (stageNumber === stepLength) {
            buttonComponents =
                <div className={styles.buttons}>
                    <Link to={`/tools/tools-and-resources`}><Button className={general.button}>Tools and resources</Button></Link>
                    <Link to={`${directory}` + `/${stageNumber - 1}`}><Button className={general.button}><FaAngleLeft /><span>Stage {stageNumber - 1}</span></Button></Link>
                </div>
        } else {
            buttonComponents =
                <div className={styles.buttons}>
                    <Link to={`${directory}` + `/${stageNumber + 1}`}><Button className={general.button}><span>Continue to Stage {stageNumber + 1}</span><FaAngleRight /></Button></Link>
                    <Link to={`${directory}` + `/${stageNumber - 1}`}><Button className={general.button}><FaAngleLeft /><span>Stage {stageNumber - 1}</span></Button></Link>
                </div>
        }

        if (this.state.isViewerOpen) {
            return (
                <ImageViewer
                    src={ this.state.currentImage }
                    onClose={ this.closeImageViewer }
                /> 
            )
        }

        return (
            <Layout>
                <SEO
                    title={stageData.title}
                    description={stageData.subTitle}
                />
                <div className={styles.root}>
                    <p className={styles.upperLeftLink}><Link to={`/`} style={{ textDecoration: 'none' }}>Home</Link> / Risk assessment / Stage {stageNumber}</p>
                    <div className={general.stageLinks}>
                        <StageLinks stageNumber={stageNumber} />
                    </div>
                    <div className={styles.containers}>
                        <div className={styles.contentContainer}>
                            <div className={styles.mainContent}>
                                {contentComponents}
                            </div>
                            {buttonComponents}
                        </div>

                        <div className={styles.sideContainer}>
                            <div className={styles.mainSideContainer}>
                                {mainSideContent}
                            </div>
                            <div className={styles.subSideContainer}>
                                {this.state.subSideContent}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default StagePage

